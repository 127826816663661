.portfolio{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 120vh;
}
.portfolio span:nth-of-type(1){
    color: var(--black);
    font-size: 2rem;
    font-weight: bold;
}
.portfolio span:nth-of-type(2){
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}
.portfolio .swiper{
    overflow: visible!important;
}
.portfolio-slider{
    margin-top: 3rem;
    width: 100%;
}
.portfolio-slider .swiper-slide{
    width: 20rem;

}
.portfolio img{
    width: 17rem;
    filter: drop-shadow(-12px 15px 13px rgba(0,0,0,0.35));
    border-radius: 19px;
}
